import React from "react";
import { HeaderTag } from "../HeaderTag";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../ui/accordion";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import WatchDemoBtn from "../WatchDemoBtn";
import { Link } from "react-router-dom";

export const handleScroll = () => {
  // Scroll to 60% of the viewport height from the top
  const scrollPosition = window.innerHeight * 0.6;
  window.scrollTo({
    top: scrollPosition,
    behavior: "smooth", // Smooth scrolling effect
  });
};

const Faq = () => {
  const { pathname } = useLocation();
  return (
    <div className="w-full bg-[#F9F9F9] py-[70px]">
      <div className="mx-auto w-full max-w-7xl px-4 lg:px-0 flex flex-col gap-6 lg:gap-[90px] lg:flex-row lg:items-start items-center justify-center">
        <div className="flex flex-col gap-5 items-center lg:items-start">
          <HeaderTag text="Urmmm? 🙋" />

          <div className="flex flex-col gap-[10px] items-center justify-center lg:items-start lg:justify-normal px-4 lg:px-0">
            <h5 className="text-[30px] leading-[38px] lg:text-[36px] lg:leading-[48px] font-bold text-center">
              Frequently asked questions
            </h5>
            <span className="text-[#161617] text-base text-center">
              Everything you need to know about the product and more
            </span>
          </div>

          <div className="w-full flex-row items-center gap-5 hidden lg:flex">
            <Link
              to="/auth"
              // href={"https://school.nurovant.com/"}
              className="flex text-center text-white bg-[#4970FC] hover:bg-[#4970FC] hover:text-white p-3 px-5 rounded-full text-base font-bold items-center justify-center"
            >
              Get Started 🔥
            </Link>
            <WatchDemoBtn />
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-[556px]">
          <Accordion
            type="single"
            collapsible
            className="w-full space-y-5 text-left"
            defaultValue="item-1"
          >
            <AccordionItem value="item-1">
              <AccordionTrigger>What is Nurovant?</AccordionTrigger>
              <AccordionContent className="px-4">
                Nurovant is an AI-powered learning companion designed to
                revolutionize digital education. It captures, analyzes, and
                facilitates understanding of lecture content, transforming dense
                information into manageable content for enhanced learning
                efficiency.
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-7">
              <AccordionTrigger>How does Nurovant work?</AccordionTrigger>
              <AccordionContent className="space-y-5 px-4">
                <p>
                  {" "}
                  1. Lecture Recording: Students initiate the process by
                  recording their lectures using the Nurovant app on their
                  mobile devices. The app can handle lecture durations ranging
                  from five minutes to an hour and a half.
                </p>
                <p>
                  {" "}
                  2. Data Transmission & Processing: After the lecture is
                  recorded, the real magic happens. Within 5-30 minutes,
                  Nurovant employs NLP to process and analyze the lecture
                  content, dissecting the material and identifying key concepts.
                </p>

                <p>
                  3. Features Utilization: Once the analysis is complete,
                  students can access several features. These include Nurovant
                  Recaps for condensed lecture summaries, LectureMate to answer
                  academic queries based on the lecture content, Recap Cards
                  that serve as digital flashcards of key concepts, and
                  QuizGenerator for self-assessment. Additionally, students can
                  create personalized study plans with the Study Guide Plans
                  feature.
                </p>
                <p>
                  {" "}
                  By incorporating AI and NLP, Nurovant creates a comprehensive,
                  convenient, and effective learning experience tailored for
                  digital education.
                </p>
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-13">
              <AccordionTrigger>
                How does Nurovant use Artificial Intelligence in its features?
              </AccordionTrigger>
              <AccordionContent className="px-4">
                Nurovant leverages advanced AI technologies to transform dense
                lecture content into manageable units, extract key insights,
                generate quizzes and flashcards, and provide interactive
                learning experiences. This intelligent use of AI helps to
                enhance comprehension and recollection of the lecture content.
              </AccordionContent>
            </AccordionItem>
            <AccordionItem value="item-6">
              <AccordionTrigger>
                Is my data secure with Nurovant?
              </AccordionTrigger>
              <AccordionContent className="px-4">
                Yes, Nurovant is committed to ensuring data security and
                privacy. We implement industry-leading encryption standards,
                privacy-by-design principles, and secure servers to protect your
                data
              </AccordionContent>
            </AccordionItem>

            <AccordionItem value="item-8">
              <AccordionTrigger>
                What are the key features of Nurovant?
              </AccordionTrigger>
              <AccordionContent className="px-4">
                Nurovant comes with several innovative features that enhance the
                learning experience, including the 'QuizGenerator' for
                self-assessment, 'LectureMate' for interactive learning, 'Recap
                Cards' for mastering key concepts, 'Nurovant Recaps' for quick
                content overviews, and 'Study Guide Plans' for personalized
                study schedules.
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
